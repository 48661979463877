<template>
  <div class="dialog-add-account" v-loading="loading">
    <div class="add-account">
      <div class="dialog-add-account__title" v-html="$t('user_data.title')"></div>
      <div class="dialog-add-account__desc">
        {{ $t('user_data.description') }}
      </div>
      <form class="dialog-add-account__form">
        <div class="dialog-add-account__form-row is-options-row">
          <div class="dialog-add-account__form-col dialog-add-account__form-col--big with-options" :class="{'with-options' : isEditable}">
            <div v-if="isEditable && showSwitcher" class="dialog-add-account__form-type">
              <label :class="{active: !form.is_legal}">
                <input type="radio" :value="false" v-model="form.is_legal">
                {{ $t('user_data.individual') }}
              </label>
              <label :class="{active: form.is_legal}">
                <input type="radio" :value="true" v-model="form.is_legal">
                {{ $t('user_data.ent_or_legal') }}
              </label>
            </div>
            <div v-else-if="!isEditable && showSwitcher" class="dialog-add-account__form-type">
              <label :class="{active: !form.is_legal}">
                <input type="radio" :value="false" v-model="form.is_legal">
                {{ $t('user_data.individual') }}
              </label>
              <label :class="{active: form.is_legal}">
                <input type="radio" :value="true" v-model="form.is_legal">
                {{ $t('user_data.ent_or_legal') }}
              </label>
            </div>
            <div
              v-if="role === 'manager' || role === 'finance'|| role === 'admin'"
              class="dialog-add-account__show-settings"
              :class="{ 'dialog-add-account__show-settings--show': showSettings }"
              @click="showSettings = !showSettings"
            >
              <img src="@/assets/icons/icon-settings.svg" alt="settings" title="settings">
              <span>{{ $t('user_data.settings') }}</span>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5625 4.46875L6.5 8.53125L2.4375 4.46875" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>

        <transition name="fade">
          <div class="dialog-add-account__settings" v-if="showSettings">
            <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
              {{ $t('user_data.role_manager') }}
            </div>
            <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
              <!-- <div class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input" :class="[{'form-input--full' : form.tracker_manager_id}]">
                      <input v-model="form.tracker_manager_id" type="text">
                      <span class="form-input__label">{{ $t('user_data.input_id') }}</span>
                    </div>
                  </div>
                </div>
              </div> -->
              <Selector
                v-if="managers && managers.length"
                class="user-data__selector"
                :title="$t('active_manager')"
                :options="managers"
                :selected="form.manager_id"
                @handleChange="getNewData"
              />
            </div>
            <!-- prefix -->
            <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
              {{ $t('user_data.id_prefix') }}
            </div>
            <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
              <div class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                  <div class="dialog-add-account__form-item">
                    <div class="form-input" :class="[{'form-input--full' : form.ba_prefix}]">
                      <input v-model="form.ba_prefix" type="text">
                      <span class="form-input__label">{{ $t('user_data.input_prefix') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- MyTarget -->
            <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale !== 'USA'" class="dialog-add-account__form-title">
              {{ $t('user_data.type_agent_mytarget') }}
            </div>
            <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale !=='USA'" class="dialog-add-account__form-block">
              <div class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                  <div class="dialog-add-account__form-item">
                    <div
                      class="form-input"
                      :class="[
                      {'form-input--full' : agency_account_type.mytarget !== 'not_receive'}
                    ]"
                    >
                      <select
                        v-model="agency_account_type.mytarget"
                        required
                        :disabled="!isEditable"
                      >
                        <option value="default">
                          {{ $t('user_data.gray') }}
                        </option>
                        <option value="white">
                          AM
                        </option>
                        <option value="profitads">
                          Profitads
                        </option>
                      </select>
                      <span class="form-input__label">
                        {{ $t('user_data.type__mytarget') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- yandex -->
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{ $t('user_data.type_yandex') }}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.yandex !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-if="locale==='RU'"
                      v-model="agency_account_type.yandex"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <select
                      v-if="locale==='USA'"
                      v-model="agency_account_type.yandex"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE Yandex
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{ $t('user_data.choose_type_yandex') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- tiktok -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale === 'USA'" class="dialog-add-account__form-title">
            {{ $t('user_data.type_tiktok') }}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale === 'USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.tiktok !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-if="locale==='RU'"
                      v-model="agency_account_type.tiktok"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <select
                      v-if="locale==='USA'"
                      v-model="agency_account_type.tiktok"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE TikTok
                      </option>
                      <option value="am">
                        AM TikTok
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{ $t('user_data.choose_type_tiktok') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- vkontante -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale !== 'USA'" class="dialog-add-account__form-title">
           {{ $t('user_data.type_vkontakte') }}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale !== 'USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.vkontakte !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.vkontakte"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{ $t('user_data.choose_type_vkontakte') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- vkreklama -->
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{ $t('user_data.type_vk_ad') }}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.vkads !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-if="locale==='RU'"
                      v-model="agency_account_type.vkads"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <select
                      v-if="locale==='USA'"
                      v-model="agency_account_type.vkads"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE VKAds
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{ $t('user_data.choose_type_vk_ad') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- meta -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-title">
            {{$t('business_account_data.type_fb_agent')}}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.facebook !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.facebook"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE MetaUSD
                      </option>
                      <option value="digital_pln">
                        DE MetaPLN
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('user_data.choose_type_meta')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- google -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-title">
            {{$t('user_data.type_google')}}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.google !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.google"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE Google
                      </option>
                      <option value="easystart">
                        DE GoogleES
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('user_data.choose_type_google')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <!-- Course markup -->
            <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.course_markup')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div v-if="activeAccount" class="dialog-add-account__form-col dialog-add-account__form-col--big course-percent">
                <div  v-for="(percent, currency) in form.course_percents" :key="currency"  class="dialog-add-account__form-item">
                  <div class="form-input course-markup" :class="{ 'form-input--full': form.course_percents }">
                    <input v-model="form.course_percents[currency]" type="text">
                    <span class="form-input__label">{{ currency }}, %</span>
                  </div>
                </div>
              </div>
              <div v-else  class="dialog-add-account__form-col dialog-add-account__form-col--big course-percent">
                <div class="dialog-add-account__form-item">
                  <div v-if="locale==='RU'" class="form-input course-markup" :class="[{'form-input--full' : ('USD' in form.course_percents && form.course_percents['USD'])}]">
                    <input v-model="form.course_percents.USD" type="text">
                    <span class="form-input__label"> USD, %</span>
                  </div>
                  <div v-if="locale==='USA'" class="form-input course-markup" :class="[{'form-input--full' : ('PLN' in form.course_percents && form.course_percents['PLN'])}]">
                    <input v-model="form.course_percents.PLN" type="text">
                    <span class="form-input__label"> PLN, %</span>
                  </div>
                </div>
                <div class="dialog-add-account__form-item">
                  <div class="form-input course-markup" :class="[{'form-input--full' : ('EUR' in form.course_percents && form.course_percents['EUR'])}]">
                    <input v-model="form.course_percents.EUR" type="text">
                    <span class="form-input__label"> EUR, %</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
              <div class="dialog-add-account__form-row">
                <div v-if="locale==='RU'" class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="dialog-add-account__form-title">{{$t('user_data.vat_settings')}}</div>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.yandex"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.yandex_vat')">
                        </span>
                      </span>
                    </label>

                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.vkontakte"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.vk_vat')">
                        </span>
                      </span>
                    </label>

                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.vkads"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.vk_add_vat')">
                        </span>
                      </span>
                    </label>

                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.mytarget"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.mytarget_vat')"></span>
                      </span>
                    </label>
                    <label v-if="locale==='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.facebook"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title"  v-html="$t('user_data.facebook_vat')"></span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.google"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.google_vat')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.telegram"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.telegram_vat')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.promopages"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.promopages_vat')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.avito"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.avito_vat')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.vkmarket"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.vkmarket_vat')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.metricapro"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.metricapro_vat')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale==='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.tiktok"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.tiktok_vat')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.tax_conditions.mediaplatform"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.mediaplatform_vat')">
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <!-- Visibility -->
                <div class="dialog-add-account__form-col">
                  <div class="dialog-add-account__form-item">
                    <div class="dialog-add-account__form-title">{{ $t('user_data.visibility_settings') }}</div>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.yandex"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_yandex')">
                        </span>
                      </span>
                    </label>

                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.vkontakte"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_vk')">
                        </span>
                      </span>
                    </label>

                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.vkads"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_vk_ad')">
                        </span>
                      </span>
                    </label>

                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.mytarget"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_mytarget')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale==='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.facebook"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.shov_fb')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.google"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_google')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale==='RU'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.visible_services.telegram"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_telegram')">
                      
                        </span>
                      </span>
                    </label>
                    <label v-if="locale==='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.tiktok"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_tiktok')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.promopages"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_promopages')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.avito"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_avito')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.vkmarket"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_vkmarket')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.metricapro"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_metricapro')">
                        </span>
                      </span>
                    </label>
                    <label class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.mediaplatform"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('user_data.show_mediaplatform')">
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Automatization -->
            <div v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="dialog-add-account__form-title">
              {{$t('business_account_data.automatization')}}
            </div>
            <div v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="dialog-add-account__form-block">
              <div class="dialog-add-account__form-row">
                <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                  <label v-if="locale==='RU'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.auto_acts"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.auto1c')"> 
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.auto_cashback"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.auto_cashback')"> 
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.access_api"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('user_data.access_api')"> 
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </transition>
        
        <div v-if="!form.is_legal">
          <div class="dialog-add-account__form-row">
            <div class="dialog-add-account__form-col">
              <div class="form-input" :class="[{'form-input--full' : form.last_name},{'form-input--error' : errors.last_name || (authErrors && authErrors.last_name)}]">
                <input v-model="form.last_name" type="text" required>
                <span class="form-input__label">{{ $t('user_data.lastName') }}</span>
                <div v-if="errors.last_name" class="form-input__error" v-text="errors.last_name" />
                <div v-if="authErrors && authErrors.last_name" class="form-input__error">
                  <p v-for="(err, index) in authErrors.last_name" :key="index">
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.first_name},{'form-input--error' : errors.first_name || (authErrors && authErrors.first_name)}]">
                  <input v-model="form.first_name" type="text" required>
                  <span class="form-input__label">{{ $t('user_data.firstName') }}</span>
                  <div v-if="errors.first_name" class="form-input__error" v-text="errors.first_name" />
                  <div v-if="authErrors && authErrors.first_name" class="form-input__error">
                    <p v-for="(err, index) in authErrors.first_name" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="locale==='RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.patronymic},{'form-input--error' : errors.patronymic || (authErrors && authErrors.patronymic)}]">
                  <input v-model="form.patronymic" type="text" required>
                  <span class="form-input__label">{{ $t('user_data.patronymic') }}</span>
                  <div v-if="errors.patronymic" class="form-input__error" v-text="errors.patronymic" />
                  <div v-if="authErrors && authErrors.patronymic" class="form-input__error">
                    <p v-for="(err, index) in authErrors.patronymic" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.email},{'form-input--error' : errors.email || (authErrors && authErrors.email)}]">
                  <input v-model="form.email" type="text" required>
                  <span class="form-input__label">{{ $t('user_data.e-mail') }}</span>
                  <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
                  <div v-if="authErrors && authErrors.email" class="form-input__error">
                    <p v-for="(err, index) in authErrors.email" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.phone},{'form-input--error' : errors.phone || (authErrors && authErrors.phone)}]">
                  <input v-if="locale==='RU'" v-model="form.phone" type="text" v-mask="'+7(###)###-##-##'" required>
                  <input v-if="locale==='USA'" v-model="form.phone" type="text" v-mask="'+############'" required>
                  <span class="form-input__label">{{ $t('user_data.phone_number') }}</span>
                  <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
                  <div v-if="authErrors && authErrors.phone" class="form-input__error">
                    <p v-for="(err, index) in authErrors.phone" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale==='RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.inn}, {'form-input--error' : errors.inn|| (authErrors && authErrors.inn)}]">
                  <input v-model="form.inn" type="text" required>
                  <span class="form-input__label">{{ $t('inn') }}</span>
                  <div v-if="errors.inn" class="form-input__error" v-text="errors.inn" />
                  <div v-if="authErrors && authErrors.inn" class="form-input__error">
                    <p v-for="(err, index) in authErrors.inn" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div v-if="locale==='RU'" class="dialog-add-account__form-notify">
           {{ $t('user_data.get_receipt') }}
          </div>
          <div v-if="locale==='RU'" class="dialog-add-account__form-type is-checks">
            <label :class="{active: form.receiving_checks_method === 'mail'}">
              <input type="radio" :value="'mail'" v-model="form.receiving_checks_method">
              {{ $t('user_data.to_the_mail') }}
            </label>
          </div>
          <div v-if="isEditable && showSwitcher" class="dialog-add-account__form-agree">
            <label v-if="locale!=='USA'" class="input__item-checkbox">
              <input
                  type="checkbox"
                  value="'all'"
                  required
                  v-model="form.offer_agreement_accepted"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span  class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('user_data.agree_with') }}
                <a href="https://profitads.ru/dogovor-oferty-fl/" target="_blank">{{ $t('user_data.offer_contract') }}</a>
              </span>
            </span>
            </label>
            <div v-if="errors.offer_agreement_accepted && locale!=='USA'" class="form-input__error" v-text="errors.offer_agreement_accepted" />
          </div>
          <div v-else-if="showSwitcher" class="dialog-add-account__form-agree">
            <label v-if="locale!=='USA'" class="input__item-checkbox">
              <input
                  type="checkbox"
                  value="'all'"
                  required
                  v-model="form.offer_agreement_accepted"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('user_data.agree_with') }}
                <a href="https://profitads.ru/dogovor-oferty-fl/" target="_blank">{{ $t('user_data.offer_contract') }}</a>
              </span>
            </span>
            </label>
            <div v-if="errors.offer_agreement_accepted && locale!=='USA'" class="form-input__error" v-text="errors.offer_agreement_accepted" />
          </div>
          <div class="dialog-add-account__form-buttons">
            <ButtonElement
              :text="$t('button_props.save')"
              @click-button="saveData"
            />
            <ButtonElement
              :text="$t('button_props.cancel')"
              view="empty"
              @click-button="closeDialog"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import formValidate from '../../mixin/formValidate'
import ButtonElement from '../../components/elements/ButtonElement'
import Selector from "@/components/sort/Selector";

export default {
  name: 'UserData',
  components: {
    ButtonElement,
    Selector
  },
  mixins: [formValidate],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: false,
      form: {
        inn:'',
        is_legal: false,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        receiving_checks_method: 'mail',
        offer_agreement_accepted: '',
        tracker_manager_id: '',
        course_percents: {EUR: 5, USD: 5, PLN: 5},
        tax_conditions: {
          mytarget: process.env.VUE_APP_LOCALE==='RU'? true:false,
          vkontakte: process.env.VUE_APP_LOCALE==='RU'? false:false,
          facebook: process.env.VUE_APP_LOCALE==='RU'? false:false,
          yandex: process.env.VUE_APP_LOCALE==='RU'? false:false,
          vkads: process.env.VUE_APP_LOCALE==='RU'? true:false,
          google: process.env.VUE_APP_LOCALE==='RU'? true : false,
          tiktok: process.env.VUE_APP_LOCALE==='RU'? true : false,
          telegram: process.env.VUE_APP_LOCALE==='RU'? true : false,
          promopages: process.env.VUE_APP_LOCALE==='RU'? true : false,
          avito: process.env.VUE_APP_LOCALE==='RU'? false : false,
          vkmarket: process.env.VUE_APP_LOCALE==='RU'? false : false,
          metricapro: process.env.VUE_APP_LOCALE==='RU'? false : false,
          mediaplatform: process.env.VUE_APP_LOCALE==='RU'? false : false
        },
        visible_services: {
          mytarget: process.env.VUE_APP_LOCALE==='RU'? true: false,
          vkontakte: process.env.VUE_APP_LOCALE==='RU'? true: false,
          facebook: process.env.VUE_APP_LOCALE==='RU'? false: true,
          yandex: process.env.VUE_APP_LOCALE==='RU'? true: false,
          google: process.env.VUE_APP_LOCALE==='RU'? false: true,
          vkads: process.env.VUE_APP_LOCALE==='RU'? true: false,
          tiktok: process.env.VUE_APP_LOCALE==='RU'? false: true,
          telegram: process.env.VUE_APP_LOCALE==='RU'? true: true,
          promopages: process.env.VUE_APP_LOCALE==='RU'? true : false,
          avito: process.env.VUE_APP_LOCALE==='RU'? true : true,
          vkmarket: process.env.VUE_APP_LOCALE==='RU'? true : true,
          metricapro: process.env.VUE_APP_LOCALE==='RU'? true : true,
          mediaplatform: process.env.VUE_APP_LOCALE==='RU'? true : true
        },
        course_percent: 0,
        ba_prefix: '',
        auto_acts: false,
        auto_cashback: true,
        access_api: false,
        manager_id:''
      },
      errors: {
        inn:'',
        is_legal: false,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        receiving_checks_method: '',
        offer_agreement_accepted: '',
      },
      showSettings: false,
    }
  },
  computed: {
    showSwitcher() {
      if(this.$route && this.$route.name !== 'ClientsInfo' && this.isOpenUL) {
        return false
      }  
      else if(this.$route && this.$route.name === 'ClientsInfo') {
        return true
      } else if (this.$route && this.$route.name !== 'ClientsInfo' && (this.user.role !=='admin' || this.user.role !=='finance')) {
        return true
      } else {
        return false
      }
    },
    isOpenUL() {
      return this.$store.state.Dialog.isOpenUL
    },
    accountAutocomplete() {
      if(this.$route && this.$route.name === 'ClientsInfo') {
        return false
      }
      return true
    },
    userDialog: {
      get() {
        return this.$store.state.Dialog.userDialog
      },
      set(val) {
        this.$store.commit('Dialog/setData', { label: 'userDialog', data: val })
      },
    },
    isEditable() {
      return this.$store.state.Dialog.isEditable
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    user() {
      return this.$store.state.Auth.user
    },
    isLegal() {
      return this.form.is_legal
    },
    role() {
      return this.$store.state.Auth.role
    },
    agency_account_type() {
      return {
        mytarget: "profitads",
        yandex: "profitads",
        vkontakte: "profitads",
        vkads: this.locale === "RU" ? "profitads" : "default",
        google: this.locale === "RU" ? "profitads" : "default",
        tiktok: "default",
        facebook: "default"
      }
    },
    managers(){
      return this.$store.state.Managers.managersList
    },
  },
  watch: {
    isLegal(val) {
      if (val) {
        this.$store.dispatch('Dialog/setData', { label: 'activeDialog', data: 'BusinessAccountData' })
      }
    },
    
  },
  mounted() {
    if(this.role ==='manager' || this.role === 'admin') this.getData();
    this.agency_account_type.yandex = this.locale === "RU" ? "profitads" : "default"

    if (this.isEditable && this.accountAutocomplete) {
      if(this.activeAccount) {
        for (let key in this.form) {
          this.form[key] = this.activeAccount[key]
        }
      }
     
      for (let key in this.agency_account_type) {
        if (this.activeAccount && 
          this.activeAccount.agency_account_type && 
          this.activeAccount.agency_account_type[key]
        ) {
          this.agency_account_type[key] = this.activeAccount.agency_account_type[key]
        }
      }
      this.form.offer_agreement_accepted = true
    }
  },
  methods: {
    clearForm() {
      this.form.is_legal = false
      this.form.first_name = ''
      this.form.last_name = ''
      this.form.patronymic = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.inn = ''
      this.form.receiving_checks_method = 'mail'
      this.form.offer_agreement_accepted = false
    },
    saveData() {
      // if (this.isEditable) {
      //   console.log('edit')
      //   this.editAccount()
      // } else {
      //   console.log('new')
      //   this.addNewAccount()
      // }

      if(this.isEditable && !this.accountAutocomplete) {
        this.addNewAccount()
      } else if (this.isEditable && this.accountAutocomplete) {
        this.editAccount()
      } else {
        this.addNewAccount()
      }
    },
    editAccount() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.loading = true
      const dataForm = {}
      for (let key in this.form) {
        if(this.form[key]) {
          dataForm[key] = this.form[key]
        }
      }
      const prodEnv = process.env.VUE_APP_API_URL === 'https://api.ads-profit.com/api/v1'
      dataForm.manager_id = this.form.manager_id 
        ? this.form.manager_id 
        : prodEnv 
          ? '6cc2f4c6-2300-499c-b9f7-b7dc2720a404' 
          : '373434f3-c000-4728-b179-f2a54a99887d'

      dataForm.is_legal = this.form.is_legal
      dataForm.agency_account_type = {...this.agency_account_type}
      dataForm.ba_prefix = this.form.ba_prefix 
      dataForm.auto_acts = this.form.auto_acts
      dataForm.auto_cashback = this.form.auto_cashback
      dataForm.access_api = this.form.access_api
      dataForm.course_percents = this.removeEmpty(this.form.course_percents)
      const data = {
        id: this.activeAccount.id,
        data: dataForm
      }
      this.$store.dispatch('Auth/editBusinessAccount', data)
          .then(() => {
            this.loading = false
            this.dialogVisible = false

            if (dataForm.manager_id) {
              this.$store.commit('Auth/setData', { label: 'activeAccount', data: {
                ...this.activeAccount,
                manager_id: dataForm.manager_id
              }})
            }

            this.$notify({
              message: this.$t('user_data.val_save'),
              type: 'success'
            });
            this.clearForm()
            this.closeDialog()
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              message: this.$t('user_data.val_error'),
              type: 'error'
            });
          })
    },
    addNewAccount() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.loading = true
      const data = {}
      for (let key in this.form) {
        if(this.form[key]) {
          data[key] = this.form[key]
        }
      }

      const prodEnv = process.env.VUE_APP_API_URL === 'https://api.ads-profit.com/api/v1'
      data.manager_id = this.form.manager_id 
        ? this.form.manager_id 
        : prodEnv 
          ? '6cc2f4c6-2300-499c-b9f7-b7dc2720a404' 
          : '373434f3-c000-4728-b179-f2a54a99887d'

      data.agency_account_type = {...this.agency_account_type}
      data.is_legal = this.form.is_legal
      data.ba_prefix = this.form.ba_prefix
      data.auto_acts = this.form.auto_acts
      data.auto_cashback = this.form.auto_cashback
      data.access_api = this.form.access_api
      data.course_percents = this.removeEmpty(this.form.course_percents)

      if(this.locale === 'USA') {
        // data.course_percent = this.form.course_percent
      }
      if (this.role === 'admin' || this.role === 'manager' || this.role === 'finance') {
        data.user_id = this.$store.state.Dialog.userIdForAccount
        const token = localStorage.getItem('token')
        this.$store.dispatch('Auth/createBusinessAccountLikeManager', {data, token})
          .then(() => {
            this.loading = false
            this.dialogVisible = false
            this.$notify({
              message: this.$t('user_data.val_success'),
              type: 'success'
            });
            this.clearForm()
            this.closeDialog()
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              message: this.$t('user_data.val_error'),
              type: 'error'
            });
          })
      } else {
        this.$store.dispatch('Auth/createBusinessAccount', data)
          .then(() => {
            this.loading = false
            this.dialogVisible = false
            this.$notify({
              message: this.$t('user_data.val_success'),
              type: 'success'
            });
            this.clearForm()
            this.closeDialog()
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              message: this.$t('user_data.val_error'),
              type: 'error'
            });
          })
      }
    },
    validateForm() {
      if (!this.form.email) {
        this.errors.email = this.$t('user_data.val_email')
      } else {
        this.validateEmail()
      }
      if (!this.form.first_name) {
        this.errors.first_name = this.$t('user_data.val_name')
      }
      if (!this.form.last_name) {
        this.errors.last_name = this.$t('user_data.val_last_name')
      }
      if (this.locale ==='RU' && !this.form.patronymic) {
        this.errors.patronymic = this.$t('user_data.val_patronymic')
      }
      if (this.locale ==='RU' && !this.form.inn) {
        this.errors.inn = this.$t('user_data.val_inn')
      } else if(this.locale ==='RU' && this.form.inn){
        this.validateInn()
      }
      if (!this.form.phone) {
        this.errors.phone = this.$t('user_data.val_phone_number')
      } else {
        const regPhone = this.locale==='RU'? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+)?([0-9]+)$/)
        if (!regPhone.test(String(this.form.phone))) {
          this.errors.phone = this.$t('user_data.val_correct_phone')
        }
      }
      if (this.locale !=='USA' && !this.form.offer_agreement_accepted) {
        this.errors.offer_agreement_accepted = this.$t('user_data.val_agreeWith')
      }
    },
    closeDialog() {
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
    },
    removeEmpty(obj) {
      return Object.fromEntries(
          Object.entries(obj)
              .map(([k, v]) => [k, v != null && v !== "" ? v : 0])
      );
    },
    getNewData(id) {
      this.form.manager_id = id
    },
    async getData() {
      this.loading = true
      const token = localStorage.getItem('token')
      await this.$store.dispatch('Managers/getAllManagersList', { token })
      this.loading = false
    },
  }
}
</script>

<style scoped lang="scss">
.dialog-add-account {
  
  .course-percent {
      display: flex;
      flex-wrap: wrap;
      gap:2rem;
      .dialog-add-account__form-item {
        width: calc(50% - 1rem);
        height: unset;
      }
  }

    .form-input.course-markup {
      input + .form-input__label {
        font-size: 1.2rem;
        line-height: 1.4rem;
        top: 0.8rem;
      }

    }
  }
</style>
